@charset "utf-8";

/*----------------------------------------------------
	slide
----------------------------------------------------*/
.slide {
	//margin: 0 auto 40px;
	margin: -121px 0 40px;
	text-align: left;
	//height: 100vh;
	height: 700px;
	overflow: hidden;
	position: relative;
	z-index: 0;
	& &__contents {
	  list-style: none;
	  float: none;
	  margin: 0 auto;
	  padding: 0;
	}
	li {
	  margin: 0;
	  line-height: 1;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 700px;
  	max-height: 700px;
  	display: none;
  	position: absolute;
  	overflow: hidden;
  	img {
      position: absolute;
      //top: 50%;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
  	}
 }
 &[data-switch="off"] {
  li {
    display: block;
  }
 }
}

@media only screen and (max-width: 1024px) {
.slide {
  li {
    height: 400px;
  }
}
}

@media only screen and (max-width: 767px) {
.slide {
  position: relative;
  height: auto;
  margin: 0 auto;
  li {
    height: 178px;
  }
}
}


/*----------------------------------------------------
	pagination
----------------------------------------------------*/
.pagination {
	display: none;
}

/*----------------------------------------------------
	slide navigation
----------------------------------------------------*/
.slide-nav {
  display: none;
}

